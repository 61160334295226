$(document).ready(function () {
  const burgerIcon = document.querySelector(".hamburger-icon");
  const ul = document.querySelector("ul");
  const li = document.querySelectorAll("ul li");
  const skillsLink = document.querySelector(".skills-link");
  const processLink = document.querySelector(".process-link");

  const toggleNav = () => {
    // debugger;
    ul.classList.toggle("ul-active");
    burgerIcon.classList.toggle("burgerToggle");
    li.forEach((links, index) => {
      if (links.style.animation) {
        links.style.animation = "";
      } else {
        links.style.animation = `navlinksfade 0.4s ease forwards ${index / 7 + 0.2}s`;
      }
    });
  };

  burgerIcon.addEventListener("click", toggleNav);
  skillsLink.addEventListener("click", toggleNav);
  processLink.addEventListener("click", toggleNav);

  if (window.location.pathname === "/") {
    skillsLink.style.display = "block";
    processLink.style.display = "block";
  } else {
    skillsLink.style.display = "none";
    processLink.style.display = "none";
  }

  // if (window.location.pathname === "/portfolio") {
  //   $("#header, nav").css("height", "90px");
  //   $("#header").css("background-color", "#1a1860");
  // } else if (window.location.pathname === "/switch") {
  //   $("#header, nav").css("height", "90px");
  //   $("#header").css("background-color", "#0C53A2");
  // }
  // } else {
  //   $(window).on("scroll", function () {
  //     if ($(this).scrollTop() > 60) {
  //       $("#header, nav").css("height", "90px");
  //       $("#header").css("background-color", "#1A1860");
  //     } else {
  //       $("#header").css("background-color", "transparent");
  //       $("#header, nav").css("height", "120px");
  //     }
  //   });
  // }
});
